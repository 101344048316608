body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:bisque;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div{
  border: aquamarine;
  border:3em;
}

h1.title{
  background-color:lightskyblue;

  border-Bottom:5px solid rgb(63, 60, 60);
  text-Align:center;
}

.title{
  background-color:lightskyblue;
  text-Align:center;
}

.Product{
  color:darkslateblue;
border:2px solid rgb(63, 60, 60);
}